import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Staff from "./Staffs/Staff";
import Store from "./Stores/Store";
import Trans from "./Trans/Trans";
import Vouch from "./Vouch/Vouch";
import "./Account.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Account() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className="Account px-4 d-flex">
      <Helmet>
      <title>ShareeCoin | BTech - Account</title>
      </Helmet>
      <div className="account-body">
        <Row className="account-body-btn">
          <Col xs={12} className="d-grid">
            <Button
              variant="light"
              className="account-btn py-3"
              size="lg"
              onClick={() => navigate("/customer")}
            >
              {t("createTrans")}
            </Button>
          </Col>
          <Col xs={12} className="d-grid mt-3">
            <Staff />
          </Col>
          <Col xs={12} className="d-grid mt-3">
            <Store />
          </Col>
          <Col xs={12} className="d-grid mt-3">
            <Trans />
          </Col>
          <Col xs={12} className="d-grid mt-3">
            <Vouch />
          </Col>
          <Col xs={12} className="d-grid mt-3">
            <Button
              variant="light"
              type="submit"
              className="account-btn py-3"
              size="lg"
              onClick={() => {
                navigate("/changePassword");
              }}
            >
              {t("changepass")}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
