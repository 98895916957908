import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import languageEN from "./locale/en.json";
import languageAR from "./locale/ar.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: languageEN,
      },
      ar: {
        translation: languageAR,
      },
    },
    supportedLngs:['en','ar'],
    fallbackLng: "en",
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "localStorage",
        "path",
        "subdomain",
      ],
      caches:['cookie']
    },
  });

export default i18n;
