import axios from "axios";
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import * as yup from "yup";
import Cookies from "js-cookie";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";

import "./Trans.css";

export default function Staff() {
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [gridApi, setGridApi] = useState("");
  const gridRef = useRef();
  const handleShow = () => {
    setShow(true);
    setFullscreen(true);
  };

  // const token = Cookies.get("token");
  const token = localStorage.getItem("token");
  // Trans Table - start\
  const dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      const dateAsString = cellValue;
      if (dateAsString == null) return -1;
      const cellDate = new Date(dateAsString).getTime();
      if (filterLocalDateAtMidnight.getTime() === cellDate) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  const columnDefs = [
    {
      headerName: "Receipt Value",
      field: "receiptValue",
      width: 250,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: "\\d\\-\\,",
        numberParser: (text) => {
          return text == null ? null : parseFloat(text.replace(",", "."));
        },
      },
    },
    {
      headerName: "Discount",
      field: "discountValue",
      width: 250,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: "\\d\\-\\,",
        numberParser: (text) => {
          return text == null ? null : parseFloat(text.replace(",", "."));
        },
      },
    },
    {
      headerName: "Taxes",
      field: "taxes",
      width: 250,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: "\\d\\-\\,",
        numberParser: (text) => {
          return text == null ? null : parseFloat(text.replace(",", "."));
        },
      },
    },
    {
      headerName: "Additional Fees",
      field: "additionalFees",
      width: 250,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: "\\d\\-\\,",
        numberParser: (text) => {
          return text == null ? null : parseFloat(text.replace(",", "."));
        },
      },
    },
    {
      headerName: "Total",
      field: "total",
      width: 250,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: "\\d\\-\\,",
        numberParser: (text) => {
          return text == null ? null : parseFloat(text.replace(",", "."));
        },
      },
    },
    {
      headerName: "Customer Phone Number",
      field: "BTechUser.PhoneNumber",
      width: 300,
    },
    {
      headerName: "National ID",
      field: "nationalId",
      width: 300,
    },
    {
      headerName: "Date",
      field: "createdAt",
      width: 300,
      cellRenderer: (data) => {
        return moment(data.value).format("DD/MM/YYYY");
      },
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
    setGridApi(params);
    axios
      .post(
        "https://hook.eu1.make.com/fpol8u83tl47fe32jat8wyucupzw6agd",
        { token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if(res.data.statusCode === 401){
          Cookies.remove("phone_number");
          Cookies.remove("national_id");
          localStorage.clear();
          window.location.reload();
        }
        else{
          params.api.applyTransaction({ add: res.data.data.transactions.rows });
        }
      });
  }, []);
  const handleFilter = ({ sdate, edate }) => {
    const filterType = () => {
      if (sdate !== "" && edate !== "") {
        return "inRange";
      } else if (sdate !== "") {
        return "greaterThan";
      } else if (edate !== "") {
        return "lessThan";
      }
    };
    const dateFilterComponent = gridApi.api.getFilterInstance("createdAt");
    dateFilterComponent.setModel({
      type: filterType(),
      dateFrom: sdate ? sdate : edate,
      dateTo: edate,
    });
    gridApi.api.onFilterChanged();
  };

  // Trans Table - end

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      const formikEdate = new Date(values.endDate);
      formikEdate.setDate(formikEdate.getDate() + 1);
      const edatee = formikEdate.toISOString().split('T')[0];
      handleFilter({
        sdate: values.startDate,
        edate: edatee,
      });
    },
    validationSchema: yup.object().shape({
      startDate: yup
        .date()
        .max(yup.ref("endDate"), "Start date can't be after end date"),
      endDate: yup
        .date()
        .min(yup.ref("startDate"), "End date can't be before start date"),
    }),
  });

  const onExportClick = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const { t } = useTranslation();
  return (
    <div className="staff d-grid">
      <Button
        variant="light"
        type="submit"
        className="account-btn py-3"
        size="lg"
        onClick={handleShow}
      >
        {t("viewTrans")}
      </Button>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("viewTrans")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-parent py-4 mb-2">
            <Form className="filter-form px-4" onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12} md={4}>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text>From</InputGroup.Text>
                    <FormControl
                      type="date"
                      aria-label="Small"
                      className="input-text"
                      id="startDate"
                      onChange={formik.handleChange}
                      value={formik.values.startDate}
                    />
                  </InputGroup>
                  <Form.Text className="text-error">
                    {formik.errors.startDate && formik.errors.startDate}
                  </Form.Text>
                </Col>
                <Col xs={12} md={4}>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text>To</InputGroup.Text>
                    <FormControl
                      type="date"
                      aria-label="Small"
                      className="input-text"
                      id="endDate"
                      onChange={formik.handleChange}
                      value={formik.values.endDate}
                    />
                  </InputGroup>
                  <Form.Text className="text-error">
                    {formik.errors.endDate && formik.errors.endDate}
                  </Form.Text>
                </Col>
                <Col xs={12} md={4}>
                  <div className="d-grid">
                    <Button variant="danger" type="submit" size="sm">
                      Filter
                    </Button>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <Button
                    variant="success"
                    size="sm"
                    className="px-4 mt-3"
                    onClick={onExportClick}
                  >
                    Export to Excel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="ag-theme-alpine trans-table">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
