import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Components/Login/Login";
import Customer from "./Components/Customer/Customer";
import Account from "./Components/Account/Account";
import Checkout from "./Components/Checkout/Checkout";
import { Navigate } from "react-router-dom";
import Language from "./Components/Language/Language";
import Cookies from "js-cookie";
import AddUser from "./Components/Account/Staffs/AddUser/AddUser";
import Changeps from "./Components/Account/Changeps/Changeps";
import Addstore from "./Components/Account/Stores/Addstore";
import VouchForm from "./Components/Account/Vouch/VouchForm";
import Footer from "./Components/Footer/Footer";

const languages = [
  {
    code: "en",
    name: "English",
    dir: "ltr",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
  },
];
function App() {
  const token = localStorage.getItem("token");
  const code = localStorage.getItem("coupon_code");
  const noOp = function () {};

  window.console = {
    log: noOp,
    dir: noOp,
  };

  return (
    <Router>
      <div className="App">
        <Footer />
        <Routes>
          {token ? (
            <>
              <Route path="*" element={<Navigate to="/account" />}></Route>
              <Route path="/" element={<Navigate to="/account" />}></Route>
              <Route path="/customer" element={<Customer />}></Route>
              <Route path="/account" element={<Account />}></Route>
              <Route path="/register" element={<AddUser />}></Route>
              <Route path="/changePassword" element={<Changeps />}></Route>
              <Route path="/addStore" element={<Addstore />}></Route>
              <Route path="/addVouch" element={<VouchForm />}></Route>
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />}></Route>
              <Route path="*" element={<Navigate to="/login" />}></Route>
              <Route path="/" element={<Navigate to="/login" />}></Route>
            </>
          )}
          {code ? (
            <>
              <Route path="/checkout" element={<Checkout />}></Route>
            </>
          ) : (
            <>
              <Route
                path="/checkout"
                element={<Navigate to="/customer" />}
              ></Route>
            </>
          )}
        </Routes>
        <Language />
      </div>
    </Router>
  );
}

export { languages };
export default App;
