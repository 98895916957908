import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  FormControl,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { register } from "../../../../Redux/userSlice";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Cookies from "js-cookie";
import { MdError } from "react-icons/md";
import "./AddUser.css";

export default function AddUser() {
  const [stores, setStores] = useState([]);
  const [show, setShow] = useState(false);
  const error = useSelector((state) => state.users.add_error);
  const error_ar = useSelector((state) => state.users.add_error_ar);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      full_name: "",
      role: "",
      phone_number: "",
      password: "",
      store: "",
    },
    onSubmit: (values) => {
      dispatch(
        register({
          name: values.full_name,
          role: values.role,
          phoneNumber: "+20" + values.phone_number,
          password: values.password,
          storeId: values.store,
        })
      );
    },
    validationSchema: yup.object().shape({
      full_name: yup.string().required("*Full name is required"),
      role: yup.string().required("*Role is required"),
      phone_number: yup.number().required("*Mobile number is required"),
      password: yup
        .string()
        .required("*Password is required")
        .min(6, "*Password must be more than 6 characters")
        .max(10, "*Password must be less than 10 characters"),
      store: yup.number().required("Store name is required"),
    }),
  });

  // const token = Cookies.get("token");
  const token = localStorage.getItem("token");

  useEffect(() => {
    try {
      axios
        .post(
          "https://hook.eu1.make.com/uyi4pn2prj0yf5yawjv7nivw8vm253sj",
          { token },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => setStores(res.data.data.stores.rows));
    } catch (error) {}
  }, []);

  const status = useSelector((state) => state.users.status);
  useEffect(() => {
    if (status === 200) {
      navigate("/account");
    }
  });

  const { t } = useTranslation();
  useEffect(() => {
    error && setShow(true);
  }, [error]);

  return (
    <div className="addUser mt-3">
      {document.body.dir === "ltr" ? (
        <>
          <ToastContainer position="bottom-end" className="p-3">
            <Toast
              bg="danger"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header closeButton={false}>
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body>
                {error && document.body.dir === "ltr" ? error : error_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      ) : (
        <>
          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="danger"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header closeButton={false}>
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body>
                {error && document.body.dir === "ltr" ? error : error_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      )}

      <h3 className="addUser-title pt-4 px-3">{t("addNewUser")}</h3>
      <Form className="px-5 py-5" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("fullname")}</Form.Label>
              <Form.Control
                type="text"
                className="input-text"
                id="full_name"
                onChange={formik.handleChange}
                value={formik.values.full_name}
              />
              <Form.Text className="text-error">
                {formik.errors.full_name && formik.errors.full_name}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("role")}</Form.Label>
              <Form.Select
                className="input-text"
                id="role"
                onChange={formik.handleChange}
                value={formik.values.role}
              >
                <option value="">{t("select") + " " + t("role")}</option>
                <option value="admin">Admin</option>
                <option value="store manager">Store Manager</option>
                <option value="cashier">Cashier</option>
              </Form.Select>
              <Form.Text className="text-error">
                {formik.errors.role && formik.errors.role}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">
                {t("phone_number")}
              </Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1" className="phone-addon">
                  <img src={require("../../../../imgs/egypt.png")} alt="flag" />
                </InputGroup.Text>
                <FormControl
                  type="number"
                  placeholder={t("enter") + " " + t("phone_number")}
                  className="input-text"
                  id="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                />
              </InputGroup>
              <Form.Text className="text-error">
                {formik.errors.phone_number && formik.errors.phone_number}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("password")}</Form.Label>
              <Form.Control
                type="password"
                className="input-text"
                id="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <Form.Text className="text-error">
                {formik.errors.password && formik.errors.password}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("storename")}</Form.Label>
              {document.body.dir === "ltr" ? (
                <>
                  <Form.Select
                    className="input-text"
                    id="store"
                    onChange={formik.handleChange}
                    value={formik.values.store}
                  >
                    <option value="">
                      {t("select") + " " + t("storename")}
                    </option>
                    {stores.map((s) => {
                      return (
                        <option value={s.id} key={s.id}>
                          {s.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </>
              ) : (
                <Form.Select
                  className="input-text"
                  id="store"
                  onChange={formik.handleChange}
                  value={formik.values.store}
                >
                  <option value="">{t("select") + " " + t("storename")}</option>
                  {stores.map((s) => {
                    return (
                      <option value={s.id} key={s.id}>
                        {s.nameAr}
                      </option>
                    );
                  })}
                </Form.Select>
              )}

              <Form.Text className="text-error">
                {formik.errors.store && formik.errors.store}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="submit-btn">
          {t("add")}
        </Button>
      </Form>
    </div>
  );
}
