import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "./Login.css";
import { Helmet } from "react-helmet";
import { login } from "../../Redux/userSlice";
import { MdError } from "react-icons/md";

export default function Login() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.users.token);
  const role = useSelector((state) => state.users.login_role);
  const name = useSelector((state)=>state.users.name);
  useEffect(() => {
    if (token) {
      if(role === "admin"){
        localStorage.setItem('role',`${role}@99`);
      }
      else if(role === "store manager"){
        localStorage.setItem('role',`${role}_20`);
      }
      else if(role === "cashier"){
        localStorage.setItem('role',`${role}&50`);
      }
      localStorage.setItem('token',token);
      localStorage.setItem('name',name);
      window.location.reload();
    }
  }, [token]);

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(
        login({
          phoneNumber: "+2" + values.phoneNumber,
          password: values.password,
        })
      );
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.string().required("*Mobile number is required"),
      password: yup
        .string()
        .required("*Password is required")
        .min(6, "*Password must be more than 6 characters")
        .max(10, "*Password must be less than 10 characters"),
    }),
  });

  const error = useSelector((state) => state.users.login_error);
  const error_ar = useSelector((state) => state.users.login_error_ar);

  useEffect(() => {
    error && setShow(true);
  }, [error]);

  const { t } = useTranslation();

  return (
    <div className="Login px-3">
      <Helmet>
        <title>ShareeCoin | BTech - Login</title>
      </Helmet>
      {document.body.dir === "ltr" ? (
        <>
          <ToastContainer position="bottom-end" className="p-3">
            <Toast
              bg="danger"
              onClose={() => {
                setShow(false);
              }}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header closeButton={false}>
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body>
                {error && document.body.dir === "ltr" ? error : error_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      ) : (
        <>
          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="danger"
              onClose={() => {
                setShow(false);
              }}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header closeButton={false}>
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body>
                {error && document.body.dir === "ltr" ? error : error_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      )}

      <Container fluid>
        <Row>
          <Col className="character me-5 pt-5">
            <img
              src={require("../../imgs/character.png")}
              alt="shareecoin-character"
            />
          </Col>
          <Col className="login-parent">
            <Row>
              <Col
                className="shareecoin-logo d-flex justify-content-center"
                xs={12}
              >
                <img
                  src={require("../../imgs/Sharee Coin LOGO transparent red.png")}
                  alt="shareecoin-logo"
                />
              </Col>
              <Col xs={12}>
                <Form
                  className="login-form px-4"
                  onSubmit={formik.handleSubmit}
                >
                  <Form.Group className="mb-3">
                    <Form.Label className="label-text">
                      {t("phone_number")}
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        className="phone-addon"
                      >
                        <img src={require("../../imgs/egypt.png")} alt="flag" />
                      </InputGroup.Text>
                      <FormControl
                        type="tel"
                        maxLength="11"
                        placeholder={t("enter") + " " + t("phone_number")}
                        className="input-text"
                        id="phoneNumber"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                      />
                    </InputGroup>
                    <Form.Text className="text-error">
                      {formik.errors.phoneNumber && formik.errors.phoneNumber}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="label-text">
                      {t("password")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("enter") + " " + t("password")}
                      className="input-text"
                      id="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <Form.Text className="text-error">
                      {formik.errors.password && formik.errors.password}
                    </Form.Text>
                  </Form.Group>
                  <Button variant="" type="submit" className="login-btn">
                    {t("login")}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
