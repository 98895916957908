import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import { Row, Col, ToastContainer,Toast } from "react-bootstrap";
import { MdError } from "react-icons/md";
import { addVouch } from "../../../Redux/vouchSlice";
import "./VouchForm.css";

export default function VouchForm() {
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const message = useSelector((state) => state.vouchs.add_message);
  const message_ar = useSelector((state) => state.vouchs.add_message_ar);
  const validate = yup.object().shape({
    vouchs: yup
      .array()
      .of(
        yup.object().shape({
          total: yup
            .number()
            .typeError("*Voucher value must be number")
            .required("*Voucher value is required"),
          vouch_discount: yup
            .number()
            .typeError("*Voucher discount must be number")
            .required("*Voucher discount is required"),
        })
      )
      .required("*Vouchers is required")
      .min(1, "*Minimum 1 Voucher"),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    message && setShow(true);
  }, [message]);

  return (
    <div className="vouchForm px-5">
      {document.body.dir === "ltr" ? (
        <>
          <ToastContainer position="bottom-end" className="p-3">
            <Toast
              bg="success"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header
                closeButton={false}
                className="toast-header-success"
              >
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body className="toast-body-success">
                {message && document.body.dir === "ltr" ? message : message_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      ) : (
        <>
          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="success"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header
                closeButton={false}
                className="toast-header-success"
              >
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body className="toast-body-success">
                {message && document.body.dir === "ltr" ? message : message_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      )}
      <h3 className="changeps-title pt-4 px-3 mb-5">Create New Vouchers</h3>
      <Formik
        initialValues={{
          vouchs: [
            {
              total: "",
              vouch_discount: "",
            },
          ],
        }}
        validationSchema={validate}
        onSubmit={(values,{resetForm}) => {
          dispatch(addVouch({ vouchs: values.vouchs }));
          message && setShow(true);
          resetForm();
        }}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="vouchs"
              render={(arrayHelpers) => (
                <div>
                  {values.vouchs.map((friend, index) => (
                    <div key={index} className="px-5 py-2">
                      {/** both these conventions do the same */}
                      <Row>
                        <Col xs={12} md={4}>
                          <Field
                            name={`vouchs[${index}].total`}
                            className="form-control form-control-sm input-text"
                            placeholder="Enter voucher value"
                          />
                          <p className="text-error">
                            <ErrorMessage name={`vouchs.${index}.total`} />
                          </p>
                        </Col>

                        <Col xs={12} md={4}>
                          <Field
                            name={`vouchs.${index}.vouch_discount`}
                            className="form-control form-control-sm input-text"
                            placeholder="Enter voucher discount"
                          />
                          <p className="text-error">
                            <ErrorMessage
                              name={`vouchs.${index}.vouch_discount`}
                            />
                          </p>
                        </Col>
                        <Col xs={12} md={4} className="d-grid">
                          <button
                            type="button"
                            onClick={() => {
                              if (values.vouchs.length === 1) {
                                setDisabled(true);
                              } else {
                                arrayHelpers.remove(index);
                              }
                            }}
                            className="btn btn-danger btn-sm remove-btn"
                          >
                            Remove
                          </button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <div className="px-5">
                    <Row>
                      <Col className="d-grid">
                        <button
                          type="button"
                          onClick={() => {
                            setDisabled(false);
                            arrayHelpers.push({
                              total: "",
                              vouch_discount: "",
                            });
                          }}
                          className="btn btn-success btn-sm submit-btn mb-2 "
                        >
                          Add
                        </button>
                      </Col>
                      <Col className="d-grid">
                        <button
                          type="submit"
                          className="btn btn-success btn-sm submit-btn send-btn"
                        >
                          Send
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
