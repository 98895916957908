import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
  receipt_value: "",
  discount_value: "",
  taxes: "",
  additional_fees: "",
  total: "",
  national_id: "",
  phone_number: "",
  coupon_code: "",
  message: "",
  date: "",
  showPrint: false,
};

const token = localStorage.getItem("token");

export const handleCheckout = createAsyncThunk(
  "receipt/handleCheckout",
  async (info, { rejectWithValue }) => {
    try {
      let result = await axios.post(
        "https://hook.eu1.make.com/fscmisw6vv77u1s2qhgy1pibcpovhti6",
        {
          receiptValue: info.receiptValue,
          discountValue: info.discount,
          taxes: 0,
          additionalFees: 0,
          total: info.total,
          nationalId: info.national_id,
          couponCode: info.couponcode,
          phoneNumber: info.phone_number,
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const receiptSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {},
  extraReducers: {
    [handleCheckout.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 401) {
        Cookies.remove("phone_number");
        Cookies.remove("national_id");
        localStorage.clear();
        window.location.reload();
      } else {
        state.message = action.payload.data.data.message;
        state.date = action.payload.data.data.transaction.createdAt.val;
        state.receipt_value = action.payload.data.data.transaction.receiptValue;
        state.discount_value =
          action.payload.data.data.transaction.discountValue;
        state.taxes = action.payload.data.data.transaction.taxes;
        state.additional_fees =
          action.payload.data.data.transaction.additionalFees;
        state.total = action.payload.data.data.transaction.total;
        state.national_id = action.payload.data.data.transaction.nationalId;
        state.phone_number = action.payload.data.data.transaction.total;
        state.coupon_code = action.meta.arg.couponcode;
        Cookies.set(
          "receipt_value",
          action.payload.data.data.transaction.receiptValue
        );
        Cookies.set("taxes", action.payload.data.data.transaction.taxes);
        Cookies.set(
          "fees",
          action.payload.data.data.transaction.additionalFees
        );
        Cookies.set("total", action.payload.data.data.transaction.total);
        state.showPrint = true;
      }
    },
    [handleCheckout.rejected]: (state, action) => {
      state.message = action.payload.response.data.message;
    },
  },
});

export default receiptSlice.reducer;
