import React from "react";
import Cookies from "js-cookie";

export const Receipt = React.forwardRef((props, ref) => {
  const phoneNumber = Cookies.get("phone_number");
  const couponcode = localStorage.getItem("coupon_code");
  const receiptValue = props.receiptValue;
  const discount = localStorage.getItem("discount");
  const total = props.total
  return (
    <div className="receipt-child py-5 px-3" ref={ref}>
      <h4 className="title">Phone Number</h4>
      <h5 className="value">{phoneNumber}</h5>
      <h5>--------------------------------------------</h5>
      <h4 className="title">Coupon Code</h4>
      <h5 className="value">{couponcode}</h5>
      <h5>--------------------------------------------</h5>
      <table className="table table-borderless">
        <tbody>
          <tr className="border mt-3">
            <td>Receipt Value</td>
            <td className="font-st">{receiptValue}</td>
          </tr>
          <tr>
            <td>Discount</td>
            <td className="font-st">{discount}</td>
          </tr>
          <tr className="border mt-3">
            <td>Total</td>
            <td className="font-st">{total}</td>
          </tr>
        </tbody>
      </table>

    </div>
  );
});
