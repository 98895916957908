import React, { useState, useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import "./Customer.css";
import { BsUpcScan } from "react-icons/bs";
import { MdError } from "react-icons/md";
import { doSubmit } from "../../Redux/customerSlice";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

export default function Customer() {
  const [couponCode, setCouponCode] = useState("");
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [showw, setShoww] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const error = useSelector((state) => state.customers.error);
  const error_ar = useSelector((state) => state.customers.error_ar);

  const formik = useFormik({
    initialValues: {
      national_id: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      coupon_code: "",
    },
    onSubmit: (values) => {
      dispatch(
        doSubmit({
          nationalID: values.national_id,
          firstName: values.first_name,
          lastName: values.last_name,
          phoneNumber: "002" + values.phone_number,
          couponCode: values.coupon_code,
        })
      );
    },
    validationSchema: yup.object().shape({
      national_id: yup
        .string()
        .required("*National ID is required")
        .matches(/^[0-9]{14}$/, "*National ID is required"),
      first_name: yup
        .string()
        .required("*First name is required")
        .min(3)
        .max(10)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$/, "*Enter valid first name"),
      last_name: yup
        .string()
        .required("*Last name is required")
        .min(3)
        .max(10)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$/, "*Enter valid last name"),
      phone_number: yup.number().required("*Mobile number is required"),
      coupon_code: yup.string().required("*Coupon code is required"),
    }),
  });

  const code = localStorage.getItem("coupon_code");
  useEffect(() => {
    if (code) {
      navigate("/checkout");
    }
  });

  const handleShow = () => {
    setShow(true);
    setFullscreen(true);
  };

  const handleScanWebCam = (err, result) => {
    if (result) {
      formik.values.coupon_code = result.text;
      setCouponCode(result.text);
      setShow(false);
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    error && setShoww(true);
  }, [error]);

  return (
    <div className="Customer py-4">
      <Helmet>
        <title>ShareeCoin | BTech - Customers</title>
      </Helmet>
      {document.body.dir === "ltr" ? (
        <>
          <ToastContainer position="bottom-end" className="p-3">
            <Toast
              bg="danger"
              onClose={() => setShoww(false)}
              show={showw}
              delay={5000}
              autohide={true}
            >
              <Toast.Header closeButton={false}>
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body>
                {error && document.body.dir === "ltr" ? error : error_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      ) : (
        <>
          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="danger"
              onClose={() => setShoww(false)}
              show={showw}
              delay={5000}
              autohide={true}
            >
              <Toast.Header closeButton={false}>
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body>
                {error && document.body.dir === "ltr" ? error : error_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      )}
      <h3 className="customer-title px-3">{t("title_customer")}</h3>
      <Form className="px-5 py-2" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("national_id")}</Form.Label>
              <Form.Control
                type="tel"
                maxLength="14"
                placeholder={t("enter") + " " + t("national_id")}
                className="input-text"
                id="national_id"
                onChange={formik.handleChange}
                value={formik.values.national_id}
              />
              <Form.Text className="text-error">
                {formik.errors.national_id && formik.errors.national_id}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("first_name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("enter") + " " + t("first_name")}
                className="input-text"
                id="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
              />
              <Form.Text className="text-error">
                {formik.errors.first_name && formik.errors.first_name}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("last_name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("enter") + " " + t("last_name")}
                className="input-text"
                id="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
              />
              <Form.Text className="text-error">
                {formik.errors.last_name && formik.errors.last_name}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">
                {t("phone_number")}
              </Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1" className="phone-addon">
                  <img src={require("../../imgs/egypt.png")} alt="flag" />
                </InputGroup.Text>
                <FormControl
                  type="tel"
                  placeholder={t("enter") + " " + t("phone_number")}
                  maxLength="11"
                  className="input-text"
                  id="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                />
              </InputGroup>
              <Form.Text className="text-error">
                {formik.errors.phone_number && formik.errors.phone_number}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("coupon_code")}</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder={t("enter") + " " + t("coupon_code")}
                  className="input-text"
                  id="coupon_code"
                  onChange={formik.handleChange}
                  value={formik.values.coupon_code}
                />
                <Button
                  variant="danger"
                  id="button-addon2"
                  onClick={handleShow}
                >
                  <BsUpcScan />
                </Button>
              </InputGroup>
              <Form.Text className="text-error">
                {formik.errors.coupon_code && formik.errors.coupon_code}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button variant="primary" type="submit" className="submit-btn py-2">
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Coupon Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BarcodeScannerComponent onUpdate={handleScanWebCam} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
