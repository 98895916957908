import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, ToastContainer, Toast } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { addStore } from "../../../Redux/storeSlice";
import { MdError } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import "./Addstore.css";
import { useTranslation } from "react-i18next";

export default function Addstore() {
  const [show, setShow] = useState(false);
  const message = useSelector((state) => state.stores.add_message);
  const message_ar = useSelector((state) => state.stores.add_message_ar);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      name_ar: "",
      address: "",
      address_ar: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        addStore({
          name: values.name,
          name_ar: values.name_ar,
          address: values.address,
          address_ar: values.address_ar,
        })
      );
      message && setShow(true);
      setSubmitting(true);
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Store name is required"),
      name_ar: yup.string().required("Store name in arabic is required"),
      address: yup.string().required("Store address is required"),
      address_ar: yup.string().required("Store address in arabic is required"),
    }),
  });

  useEffect(() => {
    message && setShow(true);
  }, [message]);

  const { t } = useTranslation();

  return (
    <div className="addstore px-5">
      <Helmet>
        <title>ShareeCoin | BTech - Add Store</title>
      </Helmet>
      {document.body.dir === "ltr" ? (
        <>
          <ToastContainer position="bottom-end" className="p-3">
            <Toast
              bg="success"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header
                closeButton={false}
                className="toast-header-success"
              >
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body className="toast-body-success">
                {message && document.body.dir === "ltr" ? message : message_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      ) : (
        <>
          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="success"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header
                closeButton={false}
                className="toast-header-success"
              >
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body className="toast-body-success">
                {message && document.body.dir === "ltr" ? message : message_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      )}
      <h3 className="changeps-title pt-4 px-3">{t("addnewstore")}</h3>
      <Form className="py-5" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("storename")}</Form.Label>
              <Form.Control
                type="text"
                className="input-text"
                id="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <Form.Text className="text-error">
                {formik.errors.name && formik.errors.name}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("storenamear")}</Form.Label>
              <Form.Control
                type="text"
                className="input-text"
                id="name_ar"
                onChange={formik.handleChange}
                value={formik.values.name_ar}
              />
              <Form.Text className="text-error">
                {formik.errors.name_ar && formik.errors.name_ar}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">
                {t("storeaddress")}
              </Form.Label>
              <Form.Control
                type="text"
                className="input-text"
                id="address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              <Form.Text className="text-error">
                {formik.errors.address && formik.errors.address}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">
                {t("storeaddressar")}
              </Form.Label>
              <Form.Control
                type="text"
                className="input-text"
                id="address_ar"
                onChange={formik.handleChange}
                value={formik.values.address_ar}
              />
              <Form.Text className="text-error">
                {formik.errors.address_ar && formik.errors.address_ar}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary"
          type="submit"
          className="submit-btn"
          disabled={formik.isSubmitting}
        >
          {t("submit")}
        </Button>
      </Form>
    </div>
  );
}
