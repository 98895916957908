import React from "react";
import { Col, Row } from "react-bootstrap";
import Logout from "../Account/Logout/Logout";
import "./Footer.css";

export default function Footer() {
  const token = localStorage.getItem("token");

  return (
    <div className="footer px-4">
      <Row>
        <Col xs={1} className="btech-logo pt-2">
          <img src={require("../../imgs/b-tech.png")} alt="btech-logo" />
        </Col>
        {token && (
          <>
            <Col xs={11} className="align-self-end d-flex justify-content-end pb-5">
              <Logout />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}
