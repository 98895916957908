import { useFormik } from "formik";
import Cookies from "js-cookie";
import * as yup from "yup";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleCheckout } from "../../Redux/receiptSlice";
import { useReactToPrint } from "react-to-print";
import { Receipt } from "./Receipt";
import { Helmet } from "react-helmet";
import ReactToPrint from "react-to-print";
import { TiArrowBack } from "react-icons/ti";
import "./Checkout.css";

export default function Checkout() {
  const couponcode = localStorage.getItem("coupon_code");
  const discount = localStorage.getItem("discount");
  const national_id = Cookies.get("national_id");
  const phone_number = Cookies.get("phone_number");

  const [receiptValue, setReceiptValue] = useState("");
  const [total, setTotal] = useState("");
  const [hide, setHide] = useState(false);
  const [showPrint, setShowPrint] = useState(false);

  const print = useSelector((state) => state.receipt.showPrint);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const refprint = useRef();
  const handlePrint = useReactToPrint({
    content: () => refprint.current,
  });

  const formik = useFormik({
    initialValues: {
      receipt_value: receiptValue,
      discount_value: discount,
      coupon_code: couponcode,
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        handleCheckout({
          receiptValue: values.receipt_value,
          discount: discount,
          taxes: 0,
          fees: 0,
          total: total,
          national_id,
          couponcode,
          phone_number,
        })
      );
      setHide(true);
    },
    validationSchema: yup.object().shape({
      receipt_value: yup.string().required("Receipt value is required"),
      discount_value: yup.string().required("Discount value is required"),
    }),
  });

  useEffect(() => {
    setReceiptValue(formik.values.receipt_value);
    setTotal(receiptValue + 0 + 0 - discount);
    setShowPrint(print);
  });

  const handleBack = () => {
    localStorage.removeItem("coupon_code");
    Cookies.remove("phone_number");
    Cookies.remove("national_id");
    Cookies.remove("total");
    Cookies.remove("fees");
    Cookies.remove("taxes");
    localStorage.removeItem("discount");
    Cookies.remove("receipt_value");
    navigate("/customer");
  };

  return (
    <div className="Checkout">
      <Helmet>
        <title>ShareeCoin | BTech - Checkout</title>
      </Helmet>
      <h3 className="checkout-title pt-4 px-3">{t("checkout")}</h3>
      <Row>
        <Col className="checkout-receipt px-5 pt-2" xs={12} md={6}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    disabled
                    className="couponcode"
                    id="coupon_code"
                    onChange={formik.handleChange}
                    value={formik.values.coupon_code}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="label-text-receipt pt-2"
                    >
                      {t("receipt_value")}
                    </InputGroup.Text>
                    <FormControl
                      type="number"
                      className="input-text-receipt"
                      id="receipt_value"
                      onChange={formik.handleChange}
                      value={formik.values.receipt_value}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </InputGroup>
                  <Form.Text className="text-error">
                    {formik.errors.receipt_value && formik.errors.receipt_value}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="label-text-receipt pt-2"
                    >
                      {t("discount_value")}
                    </InputGroup.Text>
                    <FormControl
                      type="number"
                      className="input-text-receipt"
                      id="discount_value"
                      onChange={formik.handleChange}
                      value={discount}
                      readOnly
                      disabled
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </InputGroup>
                  <Form.Text className="text-error">
                    {formik.errors.discount_value &&
                      formik.errors.discount_value}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="label-text-receipt pt-2"
                    >
                      {t("total")}
                    </InputGroup.Text>
                    <FormControl
                      type="number"
                      className="input-text-receipt"
                      id="total"
                      onChange={formik.handleChange}
                      value={total}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      readOnly
                      disabled
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <ReactToPrint
              trigger={() => (
                <div className="d-grid">
                  <button
                    className="checkout-btn mt-3 btn-danger btn-sm"
                    type="submit"
                  >
                    {t("print")}
                  </button>
                </div>
              )}
              content={() => refprint.current}
              onBeforePrint={() => {
                const receiptValue = Cookies.get("receipt_value");
                const discount = localStorage.getItem("discount");
                const total = Cookies.get("total");
              }}
              onAfterPrint={() => {
                localStorage.removeItem("coupon_code");
                Cookies.remove("phone_number");
                Cookies.remove("national_id");
                Cookies.remove("total");
                localStorage.removeItem("discount");
                Cookies.remove("receipt_value");
                navigate("/customer");
              }}
            />
            <div className="receipt mt-5">
              <Receipt
                ref={refprint}
                receiptValue={receiptValue}
                discount={discount}
                total={total}
              />
            </div>
          </Form>
        </Col>
        <Col className="checkout-img align-self-center" md={6}>
          <img src={require("../../imgs/Cash.png")} alt="checkout" />
        </Col>
        <Button
          className="back-btn btn-sm mx-2 my-2"
          variant="light"
          onClick={handleBack}
        >
          <TiArrowBack className="back-icon" />
        </Button>
      </Row>
    </div>
  );
}
