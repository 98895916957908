import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";


const initialState = {
  vouchs: [
    {
      total: "",
      vouch_discount: "",
    },
  ],
  add_message: "",
  add_message_ar: "",
};

const token = localStorage.getItem('token');

export const addVouch = createAsyncThunk(
  "vouchs/addVouch",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://hook.eu1.make.com/l7y828nrdy4du3pysz2b3oo31cldktjb",
        {
          vouchs: info.vouchs,
          token : token
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return result ;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const vouchSlice = createSlice({
  name: "vouchs",
  initialState,
  reducers: {},
  extraReducers: {
      [addVouch.fulfilled]:(state,action)=>{
        if (action.payload.data.statusCode === 401) {
          Cookies.remove("phone_number");
          Cookies.remove("national_id");
          localStorage.clear();
          window.location.reload();
        } else {
          state.add_message = action.payload.data.message
          state.add_message_ar = action.payload.data.messageAr
        }

      },
      [addVouch.rejected]:(state,action)=>{
          state.add_message = action.payload.message
      }
  },
});


export default vouchSlice.reducer;
