import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";



const initialState = {
  name: "",
  role: "",
  phoneNumber: "",
  password: "",
  storeID: "",
  status: "",
  message: "",
  login_message: "",
  login_message_ar: "",
  login_error: "",
  login_error_ar: "",
  show_error: false,
  add_error: "",
  add_error_ar: "",
  login_role: "",
  token: "",
  isLogged: false,
  changeps_message: "",
  changeps_message_ar: "",
};

export const login = createAsyncThunk(
  "users/login",
  async (cred, { rejectWithValue }) => {
    try {
      let handleLogin = await axios.post(
        "https://hook.eu1.make.com/qr6rd84k38qamow0q6wx41unkra2l3f7",
        {
          phoneNumber: cred.phoneNumber,
          password: cred.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return handleLogin;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const register = createAsyncThunk(
  "users/register",
  async (info, { rejectWithValue }) => {
    try {
      let result = await axios.post(
        "https://hook.eu1.make.com/fqr529qf4yi1pvw7z59yf4c7ottd3avw",
        {
          name: info.name,
          role: info.role,
          phoneNumber: info.phoneNumber,
          password: info.password,
          storeId: info.storeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const token = localStorage.getItem("token");

export const changePassword = createAsyncThunk(
  "users/changePassword",
  async (change, { rejectWithValue }) => {
    try {
      let changeps = await axios.patch(
        "https://hook.eu1.make.com/tm8qpyq89ji4ow7m87sp4w5bygf2xyhs",
        {
          password: change.password,
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return changeps;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.delete(
        "https://hook.eu1.make.com/xt0bcukonqgtsuqtpuohr9qqfpsfbppt",
        {
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            id: data.id,
          },
        }
      );
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const restoreUser = createAsyncThunk(
  "users/restoreUser",
  async (user, { rejectWithValue }) => {
    try {
      const restore = await axios.patch(
        "http://newserver.shareecoin.com:5001/api/btech/restoreStaff",
        {
          id: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return restore;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.name = action.payload.data.data.staff.name;
      state.role = action.payload.data.data.staff.role;
      state.phoneNumber = action.payload.data.data.staff.phoneNumber;
      state.password = action.payload.data.data.staff.password;
      state.storeID = action.payload.data.data.staff.storeId;
      state.status = action.payload.data.statusCode;
      state.message = action.payload.data.message;
    },
    [register.pending]: (state, action) => {
      state.add_error = "";
      state.add_error_ar = "";
    },
    [register.rejected]: (state, action) => {
      state.add_error = action.payload.message;
      state.add_error_ar = action.payload.messageAr;
    },
    [login.pending]: (state, action) => {
      state.login_error = "";
      state.login_error_ar = "";
    },
    [login.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200 ) {
        state.name = action.payload.data.data.staff.name;
        state.login_role = action.payload.data.data.staff.role;
        state.token = action.payload.data.data.token;
        state.login_message = action.payload.data.message;
      } else {
        state.login_error = action.payload.data.message;
        state.login_error_ar = action.payload.data.messageAr;
      }
    },

    [login.rejected]: (state, action) => {
      state.login_error = action.payload.message;
      state.login_error_ar = action.payload.messageAr;
    },

    [changePassword.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 401) {
        Cookies.remove("phone_number");
        Cookies.remove("national_id");
        localStorage.clear();
        window.location.reload();
      } else {
        state.changeps_message = action.payload.data.message;
        state.changeps_message_ar = action.payload.data.messageAr;
      }

    },
    [changePassword.rejected]: (state, action) => {
      state.changeps_message = action.payload.message;
    },
    [deleteUser.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 401) {
        Cookies.remove("phone_number");
        Cookies.remove("national_id");
        localStorage.clear();
        window.location.reload();
      } else{
        window.location.reload();

      }
    },
    [deleteUser.rejected]: (state, action) => {
    },
    [restoreUser.fulfilled]: (state, action) => {
      window.location.reload();
    },
    [restoreUser.rejected]: (state, action) => {
    },
  },
});

// export const {  } = userSlice.actions;
export default userSlice.reducer;
