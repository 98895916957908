import { configureStore,getDefaultMiddleware } from "@reduxjs/toolkit";

import customerSlice from "./customerSlice";
import receiptSlice from "./receiptSlice";
import userSlice from "./userSlice";
import storeSlice from "./storeSlice";
import vouchSlice from "./vouchSlice";


export const store = configureStore({
    reducer : {
        users : userSlice,
        receipt : receiptSlice,
        customers : customerSlice,
        stores : storeSlice,
        vouchs : vouchSlice,
    },
    middleware : (getDefaultMiddleware)=>getDefaultMiddleware({
        serializableCheck : false,
    })
})