import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
  national_id: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  coupon_code: "",
  message: "",
  error: "",
  error_ar: "",
  statusCode: "",
};

const token = localStorage.getItem("token");

export const doSubmit = createAsyncThunk(
  "customers/doSubmit",
  async (info, { rejectWithValue }) => {
    try {
      let result = await axios.post(
        "https://hook.eu1.make.com/oq6doujk5ra0o73hsqv9xulwsa6jcuj1",
        {
          nationalId: info.nationalID,
          firstName: info.firstName,
          lastName: info.lastName,
          phoneNumber: info.phoneNumber,
          couponCode: info.couponCode,
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: {
    [doSubmit.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.message = action.payload.data.message;
        state.statusCode = action.payload.data.statusCode;
        state.national_id = action.meta.arg.nationalID;
        state.first_name = action.meta.arg.firstName;
        state.last_name = action.meta.arg.lastName;
        state.phone_number = action.meta.arg.phoneNumber;
        state.coupon_code = action.meta.arg.couponCode;
        localStorage.setItem("coupon_code", action.meta.arg.couponCode);
        localStorage.setItem("discount", action.payload.data.data.voucherValue);
        Cookies.set("national_id", action.meta.arg.nationalID);
        Cookies.set("phone_number", action.meta.arg.phoneNumber);
        window.location.reload();
      }
      else if (action.payload.data.statusCode === 401){
        Cookies.remove("phone_number");
        Cookies.remove("national_id");
        localStorage.clear();
        window.location.reload();
      }
      else {
        state.error = action.payload.data.message;
        state.error_ar = action.payload.data.messageAr;
      }
    },
    [doSubmit.pending]: (state, action) => {
      state.error = "";
      state.error_ar = "";
    },
    [doSubmit.rejected]: (state, action) => {
      state.error = action.payload.message;
      state.error_ar = action.payload.messageAr;
    },
  },
});

// export const {  } = customerSlice.actions;
export default customerSlice.reducer;
