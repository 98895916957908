import React from "react";
import { Button, Badge } from "react-bootstrap";
import { GrPowerShutdown } from "react-icons/gr";
import { IoReload } from "react-icons/io5";
import { AiTwotoneHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Logout() {
  const navigate = useNavigate();
  const role = localStorage.getItem('role');
  const rolee= role.slice(0,role.length-3);
  const name = localStorage.getItem('name');
  const handleLogout = () => {
    Cookies.remove("phone_number");
    Cookies.remove("national_id");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="logout">
      <Button
        variant="success"
        className="btn-sm mx-2 py-1 home-btn"
        onClick={() => {
          navigate("/account");
        }}
      >
        {name} | {rolee}
      </Button>
      {/* <Button variant="dark" className="btn-sm mx-2 reset-btn">
        <IoReload />
      </Button> */}
      <Button
        variant="warning"
        className="btn-sm mx-2 logout-btn"
        onClick={handleLogout}
      >
        <GrPowerShutdown />
      </Button>
    </div>
  );
}
