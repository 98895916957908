import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, ToastContainer, Toast } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import "./Changeps.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../Redux/userSlice";
import { MdError } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


export default function Changeps() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const message = useSelector((state) => state.users.changeps_message);
  const message_ar = useSelector((state) => state.users.changeps_message_ar);

  const formik = useFormik({
    initialValues: {
      password: "",
      c_password: "",
    },
    onSubmit: (values) => {
      dispatch(changePassword({ password: values.password }));
      message && setShow(true);
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required("*New Password is required")
        .min(6, "*Password must be more than 6 characters")
        .max(10, "*Password must be less than 10 characters"),
      c_password: yup
        .string()
        .required("*Confirm Password is required")
        .min(6, "*Password must be more than 6 characters")
        .max(10, "*Password must be less than 10 characters")
        .oneOf([yup.ref('password'),null],'Password and confirm password does not match')
    }),
  });
  useEffect(() => {
    message && setShow(true);
  }, [message]);

  const { t } = useTranslation();

  return (
    <div className="changeps px-5">
            <Helmet>
        <title>ShareeCoin | BTech - Change Password</title>
      </Helmet>
      {document.body.dir === "ltr" ? (
        <>
          <ToastContainer position="bottom-end" className="p-3">
            <Toast
              bg="success"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header
                closeButton={false}
                className="toast-header-success"
              >
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body className="toast-body-success">
                {message && document.body.dir === "ltr" ? message : message_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      ) : (
        <>
          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="success"
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              autohide={true}
            >
              <Toast.Header
                closeButton={false}
                className="toast-header-success"
              >
                <strong className="me-auto">
                  <MdError />
                </strong>
              </Toast.Header>
              <Toast.Body className="toast-body-success">
                {message && document.body.dir === "ltr" ? message : message_ar}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      )}
      <h3 className="changeps-title pt-4 px-3">{t("changepasss")}</h3>
      <Form className="py-5" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("newpass")}</Form.Label>
              <Form.Control
                type="password"
                className="input-text"
                placeholder={t("enter") + " " + t("newpass")}
                id="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <Form.Text className="text-error">
                {formik.errors.password && formik.errors.password}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label className="label-text">{t("newpass")}</Form.Label>
              <Form.Control
                type="password"
                className="input-text"
                placeholder={t("enter") + " " + t("newpass")}
                id="c_password"
                onChange={formik.handleChange}
                value={formik.values.c_password}
              />
              <Form.Text className="text-error">
                {formik.errors.c_password && formik.errors.c_password}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="submit-btn">
          {t("submit")}
        </Button>
      </Form>
    </div>
  );
}
