import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
  name: "",
  name_ar: "",
  address: "",
  address_ar: "",
  add_message: "",
  add_message_ar: "",
};

const token = localStorage.getItem("token");

export const addStore = createAsyncThunk(
  "stores/addStore",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "http://server.shareecoin.com:5001/api/btech/addstore",
        {
          store: {
            name: info.name,
            nameAr: info.name_ar,
            address: info.address,
            addressAr: info.address_ar,
            token: token,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {},
  extraReducers: {
    [addStore.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 401) {
        Cookies.remove("phone_number");
        Cookies.remove("national_id");
        localStorage.clear();
        window.location.reload();
      } else {
        state.add_message = action.payload.data.message;
        state.add_message_ar = action.payload.data.messageAr;
      }
    },
    [addStore.rejected]: (state, action) => {
      state.add_message = action.payload.message;
    },
  },
});

export default storeSlice.reducer;
