import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import "./Vouch.css";

export default function Staff() {
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [vouch, setVouch] = useState([]);
  const [gridApi, setGridApi] = useState("");
  const gridRef = useRef();

  const handleShow = () => {
    setShow(true);
    setFullscreen(true);
  };

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  // Trans Table - start

  const columnDefs = [
    {
      headerName: "Voucher Code",
      field: "serialNum",
      width: 350,
    },
    {
      headerName: "Discount Value",
      field: "discountValue",
      width: 300,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: "\\d\\-\\,",
        numberParser: (text) => {
          return text == null ? null : parseFloat(text.replace(",", "."));
        },
      },
    },
    {
      headerName: "Customer Phone Number",
      field: "transaction.BTechUser.userNumber",
      width: 350,
    },

    {
      headerName: "Status",
      field: "status",
      width: 300,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
    setGridApi(params);
    axios
      .post(
        "https://hook.eu1.make.com/mynhyjxigng9sgpiukjbxeecgli5t6s6",
        { token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if(res.data.statusCode === 401){
          Cookies.remove("phone_number");
          Cookies.remove("national_id");
          localStorage.clear();
          window.location.reload();
        }
        else{
          params.api.applyTransaction({ add: res.data.data.vouchers.rows });
        }
      });
  }, []);

  const onExportClick = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="staff d-grid">
      <Button
        variant="light"
        type="submit"
        className="account-btn py-3"
        size="lg"
        onClick={handleShow}
      >
        {t("viewVoucs")}
      </Button>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("viewVoucs")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {role !== "cashier&50" && (
            <>
              <div className="filter-parent py-1 mb-2">
                <Button variant="warning" size="sm" className="px-4 my-2 mx-2">
                  <NavLink to="/addVouch" className="add-user-btn">
                    Create New Vouchers
                  </NavLink>
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  className="px-4 my-2 mx-2"
                  onClick={onExportClick}
                >
                  Export to Excel
                </Button>
              </div>
            </>
          )}
          <div className="ag-theme-alpine trans-table vouch-table">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
